<template>
  <v-container>
    <div class="mb-4 sub-title2">
      To give users access to all retail accounts click "Manage Global Access" button below. You can
      additionally give out retailer specific permissions by clicking the permissions button in the
      table below.
    </div>
    <div class="mb-4">
      <v-btn
        color="primary"
        @click="globalPermissions"
      >
        <v-icon left>
          group_add
        </v-icon>
        Manage Global Access
      </v-btn>
    </div>
    <div
      fluid
      style="height:20px;"
      class="mb-4 pt-0"
    >
      <v-btn
        v-if="filteredSelected.length > 0"
        color="primary"
        small
        @click="deleteConnections"
      >
        Remove Access
      </v-btn>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items-per-page="10"
      :items="retailers"
      class="elevation-1 ml-1 mt-2"
      show-select
      item-key="accountId"
    >
      <template #[`item.data-table-select`]="{ isSelected, select, item }">
        <v-simple-checkbox
          v-if="!item.isManagedAccount"
          :value="isSelected"
          @input="select($event)"
        />
      </template>
      <template #[`item.name`]="{ item }">
        {{ item.name }}
        <div v-if="item.isManagedAccount">
          connection managed by {{ item.parentAccount }}
        </div>
      </template>
      <template #[`item.permissions`]="{ item }">
        <v-badge
          color="secondary"
          overlap
          offset-x="-1"
        >
          <template #badge>
            {{ item.userCount }}
          </template>
          <v-icon
            @click="editPermissions(item)"
          >
            group_add
          </v-icon>
        </v-badge>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showPermissions"
      max-width="800"
      persistent
    >
      <v-card>
        <v-card-text class="pt-3">
          <div
            v-show="isGlobal"
          >
            The users selected below will have access to view the data from all retailers
            connected to your account. This permission will take precedence over any retailer
            specific permissions.
          </div>
          <div
            v-show="!isGlobal"
          >
            The users selected below will have access to view the data from {{ retailer.name }}.
          </div>
          <v-select
            v-model="selectedUsers"
            :items="users"
            :item-text="getUserName"
            item-value="id"
            chips
            multiple
          >
            <template #item="data">
              <v-list-item-action>
                <v-icon v-if="data.attrs.inputValue">
                  check_box
                </v-icon>
                <v-icon v-else>
                  check_box_outline_blank
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="data.item.firstName">
                    <strong>
                      {{ data.item.firstName }} {{ data.item.lastName }} <br>
                    </strong>
                    <em>
                      {{ data.item.email }}
                    </em>
                  </span>
                  <span v-else>
                    {{ data.item.email }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions class="pb-3">
          <div class="text-center flex">
            <v-btn
              color="primary"
              @click.prevent="savePermissions"
            >
              Save
            </v-btn>
            <v-btn
              outlined
              color="grey"
              class="ml-1"
              @click="showPermissions = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import accounts from '@/api/accounts';
import connections from '../_api/connections';

export default {
  name: 'ActiveRetailers',
  props: {
    retailers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tab: null,
      selected: [],
      users: [],
      selectedUsers: [],
      showPermissions: false,
      isGlobal: false,
      retailer: {},
      singleExpand: false,
      headers: [
        {
          text: 'Retailer',
          value: 'name',
          width: '60%',
        },
        {
          text: 'Permissions',
          value: 'permissions',
          align: 'center',
          sortable: false,
          width: '40%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    filteredSelected() {
      return this.selected.filter((account) => !account.isManagedAccount);
    },
  },
  methods: {
    async deleteConnections() {
      if (this.filteredSelected.length > 0) {
        let list = '<ul>';
        list += this.filteredSelected.map((account) => `<li>${account.name}</li>`).join('');
        list += '</ul>';
        if (await this.$confirm(`Are you sure you want to remove the following retailers?${list}`)) {
          const accountList = this.filteredSelected.map((account) => account.accountId);
          await connections.deleteBrandConnections(this.getActiveAccount.id, accountList);
          this.selected = [];
          this.$emit('DataChange');
        }
      }
    },
    getUserName(user) {
      if (user.firstName) {
        return `${user.firstName} ${user.lastName}`;
      }
      return user.email;
    },
    async globalPermissions() {
      await this.loadPermissions(1);
      this.isGlobal = true;
      this.showPermissions = true;
    },
    async editPermissions(item) {
      await this.loadPermissions(2, item.accountId);
      this.retailer = item;
      this.isGlobal = false;
      this.showPermissions = true;
    },
    async loadPermissions(typeId, referenceId) {
      if (this.users.length === 0) {
        const resp = await accounts.getUsers(this.getActiveAccount.id);
        this.users = resp.data;
      }
      const result = await accounts.getPermissions(this.getActiveAccount.id, typeId, referenceId);
      this.selectedUsers = result.data.map((user) => user.userId);
    },
    async savePermissions() {
      const permissions = [{
        typeId: this.isGlobal ? 1 : 2,
        referenceId: this.isGlobal ? null : this.retailer.accountId,
        delete: true,
      }];
      permissions[0].users = this.selectedUsers.map((user) => ({
        id: user,
      }));
      await accounts.createPermissions(this.getActiveAccount.id, permissions);
      if (!this.isGlobal) {
        this.retailer.userCount = this.selectedUsers.length;
      }
      this.showPermissions = false;
    },
  },
};
</script>
