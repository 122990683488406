<template>
  <v-container>
    <div
      fluid
      style="height:20px;"
      class="mb-4 pt-0"
    >
      <v-btn
        v-if="selected.length > 0"
        color="primary"
        small
        class="mb-4 pt-0"
        @click="deleteConnections"
      >
        Revoke Request
      </v-btn>
      <v-btn
        v-if="selected.length > 0"
        color="primary"
        small
        class="ml-2 mb-4 pt-0"
        @click="dialog = true"
      >
        Resend Access Request
      </v-btn>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items-per-page="10"
      :items="retailers"
      class="elevation-1 ml-1 mt-2"
      show-select
      item-key="accountId"
    >
      <template #[`item.statusDate`]="{ item }">
        {{ new Date(item.statusDate)
          .toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' }) }}
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Retailer Data Request
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-textarea
              v-model="note"
              outlined
              label="Personal Note"
            />
            <div class="caption">
              Optionally write a personal note that We'll include the retailer when sending the
              request. The following retailers will receive an invite reminder.
              <ul>
                <li
                  v-for="item in selected"
                  :key="item.accountId"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="resendConnections"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import connections from '../_api/connections';

export default {
  name: 'PendingRetailers',
  props: {
    retailers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tab: null,
      dialog: false,
      note: '',
      selected: [],
      headers: [
        {
          text: 'Retailer',
          value: 'name',
          width: '60%',
        },
        {
          text: 'Requested On',
          value: 'statusDate',
          width: '40%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  methods: {
    async deleteConnections() {
      if (this.selected.length > 0) {
        let list = '<ul>';
        list += this.selected.map((account) => `<li>${account.name}</li>`).join('');
        list += '</ul>';
        if (await this.$confirm(`Are you sure you want to remove the following retailers?${list}`)) {
          const accounts = this.selected.map((account) => account.accountId);
          await connections.deleteBrandConnections(this.getActiveAccount.id, accounts);
          this.selected = [];
          this.$emit('DataChange', 'delete');
        }
      }
    },
    async resendConnections() {
      if (this.selected.length > 0) {
        const accounts = this.selected.map((account) => account.accountId);
        await connections.resendBrandConnections(this.getActiveAccount.id, accounts, this.note);
        this.selected = [];
        this.note = '';
        this.dialog = false;
        this.$emit('DataChange', 'resend');
      }
    },
  },
};
</script>
